import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://071382005dd3ba2d06f92d1a5024e821@o4508648150269952.ingest.de.sentry.io/4508648152563792",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});